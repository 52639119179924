import { compact } from 'lodash-es';

import { formatDate } from '~/shared/utils/date-time';

import { PatientAdmissionModel } from './patient-admission.model';

export const getAdmissionLabel = (model: PatientAdmissionModel) =>
  compact([
    `${model.visitFacility},`,
    formatDate(model.admissionDate),
    model.dischargeDate ? ` - ${formatDate(model.dischargeDate)}` : undefined,
  ]).join(' ');
